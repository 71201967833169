import React, { useEffect, useState } from 'react';
import avatar from '../assets/Mawingubluelogo.png';
import Modal from 'react-modal';
import { API_URL, secret_key, currentYear, saveLogs } from '../General';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../styles/Refer.css";
import Langpage from './Langpage';
import Swal from "sweetalert2";

function Refer() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const storedData = localStorage?.getItem('user');
    const [payments, setPayments] = useState([]);
    const [referIsOpen, setReferIsOpen] = useState(false); // Modal staterefer
    const [ticketIsOpen, setTicketIsOpen] = useState(false); // Modal state ticket
    const [regions, setRegions] = useState([]);
    const [towns, setTowns] = useState([]);
    const [regionId, setRegionId] = useState('');
    const [selectedTown, setSelectedTown] = useState('');
    const [inputValues, setInputValues] = useState({
        refername: '',
        referphone: '',
        referregion: regionId,
        refertown: selectedTown,
    });

    const [ticketValues, setTicketValues] = useState({
      category: '',
      message: '',
  });
    const [loading, setLoading] = useState(false);

    let logeduserData = null;
    let custno = null;

    if (storedData) {
        try {
            const bytes = CryptoJS.AES.decrypt(storedData, secret_key);
            logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            custno = logeduserData?.Customer_no;
        } catch (error) {
            console.error("Error decrypting user data:", error);
        }
    }

    useEffect(() => {
        if (!custno) {
            console.error("No customer number found.");
            return;
        }

        const getReferralDetails = async () => {
            try {
                const response = await axios.post(`${API_URL}/get_referals`, {
                    custno: custno,
                });

                if (response.data) {
                    const sortedData = response.data.sort((a, b) => new Date(b.rcp_date) - new Date(a.rcp_date));
                    setPayments(sortedData);
                } else {
                    console.warn("No data received from API.");
                }
            } catch (error) {
                console.error("Error fetching referral data:", error);
            }
        };

        getReferralDetails();
    }, [custno]);

    useEffect(() => {
        const getRegions = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_regions`);
                setRegions(response.data);
            } catch (error) {
                console.error("Error fetching regions:", error);
            }
        };

        getRegions();
    }, []);

    useEffect(() => {
        const getTowns = async (regionId) => {
            try {
                const response = await axios.get(`${API_URL}/get_towns/${regionId}`);
                setTowns(response.data);
            } catch (error) {
                console.error("Error fetching towns:", error);
            }
        };

        if (regionId) {
            getTowns(regionId);
        }
    }, [regionId]);

    const handleReferRegionChange = (e) => {
        setRegionId(e.target.value);
        setSelectedTown('');
        setInputValues({ ...inputValues, referregion: e.target.value, refertown: '' });
    };

    const handleReferTownChange = (e) => {
        setSelectedTown(e.target.value);
        setInputValues({ ...inputValues, refertown: e.target.value });
    };
    const handleTicketChange = (e) => {
      setTicketValues({ ...ticketValues, [e.target.id]: e.target.value });
  };
    const handleReferChange = (e) => {
        setInputValues({ ...inputValues, [e.target.id]: e.target.value });
    };

    const handleReferSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = {
            cust_name: inputValues.refername,
            phone_number: inputValues.referphone,
            region_id: inputValues.referregion,
            referral_id: logeduserData.Customer_no,
            source: "PORTAL",
            town: inputValues.refertown,
            package_name: null,
        };

        try {
            const response = await axios.post(`${API_URL}/create_newlead`, formData);
            setLoading(false);

            if (response.data.success === 1) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    html: response.data.message,
                }).then(() => {
                    setReferIsOpen(false);
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error!",
                    html: response.data.message,
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error submitting referral:", error);
        }
    };

    const handleTicketSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = {
          customer_no: custno,
          category: ticketValues.category,
          message: ticketValues.message,
      };

      try {
          const response = await axios.post(`${API_URL}/create_ticket`, formData);
          setLoading(false);

          if (response.data.success) {
              Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "Ticket created successfully!",
              }).then(() => {
                  setTicketIsOpen(false);
              });
          } else {
              Swal.fire({
                  icon: "error",
                  title: "Error!",
                  text: "Failed to create ticket. Please try again.",
              });
          }
      } catch (error) {
          setLoading(false);
          console.error("Error creating ticket:", error);
      }
  };


    const handleGoBack = () => {
        navigate(-1);
    };

    const closeReferModal = () => {
        setReferIsOpen(false);
    };

    const closeTicketModal = () => {
      setTicketIsOpen(false);
  };

    return (
        <div className="overlap-group1">
            <div className="logoutlang">
                <button onClick={handleGoBack} className="back-button">
                    &lt;&lt; {t('back')}
                </button>
                <Langpage />
            </div>

            <div className="sidebar-buttons">
                <button
                    className="side-button"
                    onClick={() => setReferIsOpen(true)} // Open modal refer
                >
                    {t('Refer a Customer')}
                </button>
                {/* <button
                    className="side-button"
                    onClick={() => setTicketIsOpen(true)} // Open "Request Payment" modal
                >
                    {t('Request Payment')}
                </button> */}
            </div>

            <Modal
                isOpen={referIsOpen}
                onRequestClose={closeReferModal}
                style={{
                    content: {
                        top: '14%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        border: 'none',
                        marginTop: '40px',
                    },
                }}
            >
                <div className="modal-container">
                    <div className="modal-title">
                        <i onClick={closeReferModal} className="fa fa-window-close red-close-icon" aria-hidden="true"></i>
                    </div>
                    <div className="titlestyle">{t('refer_customer')}</div>
                    <div className="modal-content">
                        <form onSubmit={handleReferSubmit}>
                            <div className="user-details">
                                <div className="input-box">
                                    <span className="details">{t('Name')}</span>
                                    <input
                                        type="text"
                                        id="refername"
                                        required
                                        placeholder={t('Enter potential customer name')}
                                        onChange={handleReferChange}
                                    />
                                </div>
                            </div>
                            <div className="user-details">
                                <div className="input-box">
                                    <span className="details">{t('Region')}</span>
                                    <select
                                        className="selecttxt"
                                        id="referregion"
                                        onChange={handleReferRegionChange}
                                        required
                                    >
                                        <option value="">{t('Enter potential customer region')}</option>
                                        {regions.map((region, index) => (
                                            <option key={index} value={region.id}>
                                                {region.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="user-details">
                                <div className="input-box">
                                    <span className="details">{t('Town')}</span>
                                    <select
                                        className="selecttxt"
                                        value={selectedTown}
                                        id="refertown"
                                        onChange={handleReferTownChange}
                                        required
                                    >
                                        <option value="">{t('Enter potential customer town')}</option>
                                        {towns.map((town, index) => (
                                            <option key={index} value={town.name}>
                                                {town.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="user-details">
                                <div className="input-box">
                                    <span className="details">{t('Phone')}</span>
                                    <input
                                        type="text"
                                        id="referphone"
                                        required
                                        placeholder={t('Enter potential customer contact')}
                                        onChange={handleReferChange}
                                    />
                                </div>
                            </div>
                            <div className="button">
                                <input
                                    type="submit"
                                    value={loading ? t('Submitting...') : t('Submit')}
                                    disabled={loading}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
{/* Request Payment Modal */}
<Modal
    isOpen={ticketIsOpen}
    onRequestClose={closeTicketModal}
    style={{
        content: {
            top: '14%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            marginTop: '40px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9f9f9',
        },
    }}
>
    <div className="modal-container">
        <div className="modal-title">
            <i onClick={closeTicketModal} className="fa fa-window-close red-close-icon" aria-hidden="true"></i>
        </div>
        <div className="titlestyle">{t('Request Payment')}</div>
        <div className="modal-content">
            <form onSubmit={handleTicketSubmit}>
                <div className="user-details">
                    <div className="input-box">
                        <span className="details">{t('Category')}</span>
                        <input
                            type="text"
                            id="category"
                            required
                            placeholder={t('Enter category')}
                            onChange={handleTicketChange}
                            style={{
                                width: '100%',
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                fontSize: '14px',
                                color: '#333',
                                backgroundColor: '#f9f9f9',
                            }}
                        />
                    </div>
                </div>
                <div className="user-details" style={{ marginTop: '15px' }}>
                    <div className="input-box">
                        <span className="details" style={{ marginBottom: '5px' }}>{t('Message')}</span>
                        <textarea
                            id="message"
                            required
                            placeholder={t('Enter your message')}
                            onChange={handleTicketChange}
                            style={{
                                width: '100%',
                                height: '120px',
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                fontSize: '14px',
                                color: '#333',
                                backgroundColor: '#f9f9f9',
                                resize: 'none',
                            }}
                        />
                    </div>
                </div>
                <div className="button" style={{ marginTop: '20px', textAlign: 'center' }}>
                    <input
                        type="submit"
                        value={loading ? t('Submitting...') : t('Submit')}
                        disabled={loading}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: loading ? '#ccc' : '#007bff',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            cursor: loading ? 'not-allowed' : 'pointer',
                        }}
                    />
                </div>
            </form>
        </div>
    </div>
</Modal>



            <img src={avatar} alt="Avatar" className="avatar" />
            <div className="div23">
                <div className="text1-wrapper">{t('Referals')}</div>
                <div className="wraprshead">
                    <div className="text1-wrapper-3">{t('Name')}</div>
                    <div className="text1-wrapper-3">{t('Date Created')}</div>
                    <div className="text1-wrapper-3">{t('Region')}</div>
                    <div className="text1-wrapper-3">{t('Town')}</div>
                    <div className="text1-wrapper-3">{t('Status')}</div>
                </div>

                {payments.length > 0 ? (
                    payments.map((payment) => (
                        <div className="wraprs" key={payment.name}>
                            <div className="list-item">{payment.name}</div>
                            <div className="list-item">{payment.created_at}</div>
                            <div className="list-item">{payment.county}</div>
                            <div className="list-item">{payment.town}</div>
                            <div className="list-item">{payment.status}</div>
                        </div>
                    ))
                ) : (
                    <div>No referral data available.</div>
                )}
            </div>

            <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
        </div>
    );
}

export default Refer;